import { QueryCache, useMutation } from 'react-query'
import axios from 'axios'
import useAuth from 'hooks/useAuth'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { BASE_URL } from 'utils/base-url'

export function useLogin() {
  const navigate = useNavigate()
  const { updateLoggedInUserInfo } = useAuth()
  return useMutation({
    mutationFn: async ({ email, password }) => {
      try {
        const response = await axios.post(
          `${BASE_URL}/auth/login`,
          { email, password },
          { withCredentials: true },
        )

        if (response.status === 200) {
          Cookies.set('accessToken', response.data.token)
          // eslint-disable-next-line dot-notation
          axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`
          localStorage.setItem('userInfo', JSON.stringify(response?.data?.user))
          updateLoggedInUserInfo(response.data?.user)
          navigate(`/`)
        }
      } catch (error) {
        if (error.response.status === 400) {
          toast.error(error.response.data.message)
        }
      }
    },
  })
}

export function useLogout() {
  const { updateLoggedInUserInfo } = useAuth()
  const queryCache = new QueryCache()
  return useMutation({
    mutationFn: async () => {
      try {
        const response = await axios.post(`${BASE_URL}/auth/logout`, {}, { withCredentials: true })
        if (response.status === 200) {
          // eslint-disable-next-line dot-notation
          axios.defaults.headers.common['Authorization'] = ''
          localStorage.removeItem('userInfo')
          updateLoggedInUserInfo(null)
          Cookies.remove('accessToken')
          queryCache.clear()
        }
      } catch (error) {
        toast.error('Something went wrong while trying to logout you')
      }
    },
  })
}

export function useForgotPassword() {
  return useMutation({
    mutationFn: async ({ data, openModal }) => {
      try {
        const result = await axios.post(`${BASE_URL}/user/forgot-password`, data)
        if (result.data.success) {
          openModal()
        } else {
          toast.error(result.data.message)
        }
      } catch (err) {
        if (err.response.data.success === false) {
          toast.error(err.response.data.message)
        }
      }
    },
  })
}

export function useSendRegisterLinkEmail() {
  return useMutation({
    mutationFn: async ({ data, openModal }) => {
      try {
        const result = await axios.post(`${BASE_URL}/user/send-register-link`, data)
        if (result.data.success) {
          openModal()
        } else {
          toast.error(result.data.message)
        }
      } catch (err) {
        if (err.response.data.success === false) {
          toast.error(err.response.data.message)
        }
      }
    },
  })
}

export function useForgotCVC() {
  return useMutation({
    mutationFn: async ({ email }) => {
      try {
        const result = await axios.post(`${BASE_URL}/user/forgot-cvc`, { email })
        if (result.data.success) {
          toast.success(result.data.message)
        } else {
          toast.error(result.data.message)
        }
      } catch (err) {
        if (err.response.data.success === false) {
          toast.error(err.response.data.message)
        }
      }
    },
  })
}

export function useResetPassword() {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async ({ password, code, token }) => {
      try {
        const result = await axios.post(`${BASE_URL}/user/update-password`, {
          password,
          code,
          token,
        })
        if (result.data.success) {
          toast.success(result.data.message)
          navigate('/login')
        } else {
          // toast.error(result.data.message)
        }
        return result
      } catch (err) {
        if (err.response.data.success === false) {
          if (
            err.response.data.err_message === 'invalid signature' ||
            err.response.data.err_message === 'jwt expired'
          ) {
            toast.error('Sorry!!! url invalid try again...')
          }
        }
        return Promise.reject(err)
      }
    },
  })
}

export function useRegister() {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async ({ password, name, token }) => {
      try {
        const result = await axios.post(`${BASE_URL}/auth/register`, {
          password,
          name,
          token,
        })
        if (result.data.success) {
          toast.success(result.data.message)
          navigate('/login')
        } else {
          // toast.error(result.data.message)
        }
        return result
      } catch (err) {
        if (err.response.data.success === false) {
          if (
            err.response.data.err_message === 'invalid signature' ||
            err.response.data.err_message === 'jwt expired'
          ) {
            toast.error('Sorry!!! url invalid try again...')
          }
        }
        return Promise.reject(err)
      }
    },
  })
}
