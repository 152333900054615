import useAuth from 'hooks/useAuth'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

const Home = React.lazy(() => import('./home'))
const Radio = React.lazy(() => import('./radio'))
const EidCard = React.lazy(() => import('./eid-card'))
const NotFound = React.lazy(() => import('./not-found'))
const Recipes = React.lazy(() => import('./recipies'))
const EbooksAndArticles = React.lazy(() => import('./ebooks-and-articles'))
const Calender = React.lazy(() => import('./calender'))
const WriteRecipe = React.lazy(() => import('./write-recipe'))
const EditRecipe = React.lazy(() => import('./edit-recipe'))
const RecipeDetails = React.lazy(() => import('./recipe-details'))
const AddEBook = React.lazy(() => import('./add-ebook'))
const EditEbook = React.lazy(() => import('./edit-ebook'))
const RegisterFirstStep = React.lazy(() => import('./register-1'))
const Login = React.lazy(() => import('./login'))
const ForgetPassword = React.lazy(() => import('./forget-password'))
const ResetPassword = React.lazy(() => import('./reset-password'))
const CharityProfile = React.lazy(() => import('./charity-profile'))
const Charity = React.lazy(() => import('./charity'))
const CreatePage = React.lazy(() => import('./create-page'))
const Register = React.lazy(() => import('./register'))
const CreateKitchen = React.lazy(() => import('./create-kitchen'))
const KitchenProfile = React.lazy(() => import('./kitchen-profile'))
const UserProfile = React.lazy(() => import('./user-profile'))
const Kitchens = React.lazy(() => import('./kitchens'))

export default function Router() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/recipes' element={<Recipes />} />
      <Route path='/books-&-articles' element={<EbooksAndArticles />} />
      <Route path='/calender' element={<Calender />} />
      <Route
        path='/radio'
        element={
          <PrivateRoute>
            <Radio />
          </PrivateRoute>
        }
      />
      <Route path='/e-card' element={<EidCard />} />
      <Route
        path='/recipe/write'
        element={
          <PrivateRoute>
            <WriteRecipe />
          </PrivateRoute>
        }
      />
      <Route
        path='/recipe/edit/:id'
        element={
          <PrivateRoute>
            <EditRecipe />
          </PrivateRoute>
        }
      />
      <Route
        path='/ebook/edit/:id'
        element={
          <PrivateRoute>
            <EditEbook />
          </PrivateRoute>
        }
      />
      <Route path='/recipe/:id' element={<RecipeDetails />} />
      <Route
        path='/ebook/add'
        element={
          <PrivateRoute>
            <AddEBook />
          </PrivateRoute>
        }
      />
      <Route
        path='/create-kitchen'
        element={
          <PrivateRoute>
            <CreateKitchen />
          </PrivateRoute>
        }
      />
      <Route path='/register/:token' element={<Register />} />
      <Route path='/register-1' element={<RegisterFirstStep />} />
      <Route path='/login' element={<Login />} />
      <Route path='/forgot-password' element={<ForgetPassword />} />
      <Route path='/reset-password/:code/:token' element={<ResetPassword />} />
      <Route path='/charity' element={<Charity />} />
      <Route path='/charity/:url' element={<CharityProfile />} />
      <Route path='/kitchen/:url' element={<KitchenProfile />} />
      <Route path='/user/:id' element={<UserProfile />} />
      <Route
        path='/kitchens'
        element={
          <PrivateRoute>
            <Kitchens />
          </PrivateRoute>
        }
      />
      <Route
        path='/create-page'
        element={
          <PrivateRoute>
            <CreatePage />
          </PrivateRoute>
        }
      />
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}

function PrivateRoute({ children }) {
  const { loggedInUserInfo } = useAuth()
  return loggedInUserInfo ? children : <Navigate to='/login' />
}
