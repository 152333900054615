import logo from 'assets/images/logo.png'
import { navData } from 'data/navbar-data'
import useAuth from 'hooks/useAuth'
import { useState } from 'react'
import { HiOutlineMenu } from 'react-icons/hi'
import { Link, NavLink } from 'react-router-dom'
import Container from './container'
import DropDown from './menu-dropdown'
import SideNavbar from './side-navbar'

export default function Navbar() {
  const { loggedInUserInfo } = useAuth()
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(false)
  }

  return (
    <>
      <nav className='border-b shadow-sm bg-white'>
        <Container className='flex justify-between items-center py-4'>
          <Link to='/' className='font-bold italic text-2xl text-primary'>
            <img className='w-[130px] md:[160px] lg:w-[180px] h-auto' src={logo} alt='Alkhayer' />
          </Link>
          <ul className='hidden lg:flex items-center gap-2 xl:gap-6 font-medium text-lg '>
            {navData?.map((data) => (
              <li key={data.id}>
                {data?.menus ? (
                  <DropDown title={data.name} menus={data.menus} />
                ) : (
                  <NavLink
                    to={data?.link}
                    className={({ isActive }) =>
                      `ml-4 ${
                        isActive
                          ? 'border-b-2 border-primary text-primary text-md font-bold'
                          : 'underlined hover:text-primary text-md font-medium transition duration-500 focus:text-primary'
                      }`
                    }
                    target={data?.link.startsWith('https') ? '_blank' : '_self'}
                  >
                    {data?.name}
                  </NavLink>
                )}
              </li>
            ))}
            {loggedInUserInfo ? (
              <DropDown />
            ) : (
              <NavLink
                to='/login'
                className={({ isActive }) =>
                  `ml-4 ${
                    isActive
                      ? 'border-b-2 border-primary text-primary text-md font-bold'
                      : 'underlined hover:text-primary text-md font-medium transition duration-500 focus:text-primary'
                  }`
                }
                // target={data?.link.startsWith('https') ? '_blank' : '_self'}
              >
                Login
              </NavLink>
            )}
          </ul>
          <button className='block lg:hidden' onClick={() => setOpen(true)}>
            <HiOutlineMenu className='h-8 w-8' />
          </button>
        </Container>
      </nav>
      <SideNavbar open={open} handleOpen={handleOpen} />
    </>
  )
}
