// import { Triangle } from 'react-loader-spinner'
import * as animationData from 'assets/lottie/spinner.json'
import Lottie from 'react-lottie'

export default function FullPageSpinner() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <div className='min-h-screen flex items-center justify-center'>
      {/* <CirclesWithBar
        height='100'
        width='100'
        color='#4fa94d'
        wrapperStyle={{}}
        wrapperClass=''
        visible
        outerCircleColor=''
        innerCircleColor=''
        barColor=''
        ariaLabel='circles-with-bar-loading'
      /> */}

      {/* <Triangle
        height='100'
        width='100'
        color='#00074A'
        ariaLabel='triangle-loading'
        wrapperStyle={{}}
        wrapperClassName=''
        visible
      /> */}
      <Lottie options={defaultOptions} height={400} width={400} />
    </div>
  )
}
