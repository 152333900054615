import Footer from 'components/footer'
import FullPageSpinner from 'components/full-page-spinner'
import Navbar from 'components/navbar'
import Router from 'pages/router'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  const location = useLocation()

  const code = location.pathname.split('/')[2]
  const token = location.pathname.split('/')[3]

  const pathData = [
    '/login',
    '/register-1',
    `/register/${code}`,
    '/forgot-password',
    `/reset-password`,
    '/e-card',
    `/reset-password/${code}/${token}`,
  ]

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
      {pathData.includes(location.pathname) ? null : <Navbar />}
      <React.Suspense fallback={<FullPageSpinner />}>
        <Router />
      </React.Suspense>
      {pathData.includes(location.pathname) ? null : <Footer />}
    </>
  )
}

export default App
