import { BsFacebook, BsInstagram, BsLinkedin, BsTwitter, BsYoutube } from 'react-icons/bs'

import frame from 'assets/images/frame.png'
import Container from './container'

import 'react-lazy-load-image-component/src/effects/blur.css'

export default function Footer() {
  return (
    <footer
      className='text-white'
      style={{ backgroundImage: `url(${frame}), linear-gradient(109.6deg, #00074A,#02316C)` }}
    >
      <Container className='flex justify-center items-center text-center pt-28 pb-14'>
        <div>
          <h4 className='text-3xl lg:text-4xl 2xl:text-5xl font-bold mb-12 text-white'>
            Contact info
          </h4>
          <div className=''>
            <span className='text-xl'>
              (18/1/4) Farrag Mall, Central Axis, 2nd District, 6th Octobar, Giza, Egypt
            </span>
          </div>
          <div className='my-4'>
            <span className='text-xl'>(+20) 2 38367340/9</span>
          </div>
          <div className=''>
            <span className='text-xl'>info@alkhair.app</span>
          </div>
          <ul className='flex justify-center mt-6'>
            <li className='bg-primary p-2 rounded-md mr-2 hover:scale-110 ease-in-out duration-500'>
              <a href='https://www.facebook.com/afrosocial247' target='_blank' rel='noreferrer'>
                <BsFacebook />
              </a>
            </li>
            <li className='bg-primary p-2 rounded-md mx-2 hover:scale-110 ease-in-out duration-500'>
              <a href='https://twitter.com/afrosocial247' target='_blank' rel='noreferrer'>
                <BsTwitter />
              </a>
            </li>
            <li className='bg-primary p-2 rounded-md mx-2 hover:scale-110 ease-in-out duration-500'>
              <a href='https://www.instagram.com/afrosocial247' target='_blank' rel='noreferrer'>
                <BsInstagram />
              </a>
            </li>
            <li className='bg-primary p-2 rounded-md mx-2 hover:scale-110 ease-in-out duration-500'>
              <a
                href='https://www.youtube.com/channel/UC-sdlva6q-MZhUqwtTcr8TQ'
                target='_blank'
                rel='noreferrer'
              >
                <BsYoutube />
              </a>
            </li>
            <li className='bg-primary p-2 rounded-md ml-2 hover:scale-110 ease-in-out duration-500'>
              <a
                href='https://www.linkedin.com/company/afrosocial247'
                target='_blank'
                rel='noreferrer'
              >
                <BsLinkedin />
              </a>
            </li>
          </ul>
        </div>
      </Container>
      <div className='py-4 bg-[#00074A]'>
        <Container>
          <div className=''>
            <p className='text-base lg:text-lg text-center'>
              Copyright @{new Date().getFullYear()} Designed by Afro Digital & Developed by
              TechUniq@
            </p>
          </div>
        </Container>
      </div>
    </footer>
  )
}
