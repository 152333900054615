export const navData = [
  {
    id: 1,
    link: '/',
    name: 'Home',
  },
  {
    id: 2,
    link: 'https://radio.afro-group.com/public/afro_radio',
    // link: '/radio',
    name: 'Radio',
  },
  {
    id: 3,
    link: '/calender',
    name: 'Calender',
  },
  {
    id: 4,
    link: '/recipes',
    name: 'Recipes',
  },
  {
    id: 5,
    link: '/books-&-articles',
    name: 'Books & Articles',
  },
  {
    id: 6,
    link: '/e-card',
    name: 'E-Card',
  },
  {
    id: 7,
    link: '/charity',
    name: 'Charity',
  },
]
