/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-filename-extension */
import App from 'App'
import ReactDOM from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'

import Auth from 'hooks/auth'
import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache'
import 'react-lazy-load-image-component/src/effects/blur.css'
import './index.css'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <ClearBrowserCacheBoundary auto duration={60000}>
        <Auth>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Auth>
      </ClearBrowserCacheBoundary>
    </QueryClientProvider>
  </HelmetProvider>,
)

serviceWorkerRegistration.register()
reportWebVitals()
