import { Menu, Transition } from '@headlessui/react'
import { useLogout } from 'api/services/authtentication'
import useAuth from 'hooks/useAuth'
import { Fragment } from 'react'
import { AiOutlineLogout } from 'react-icons/ai'
import { HiPencil } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { IMAGE_BASE_URL } from 'utils/base-url'

import defaultImage from 'assets/images/dummy-profile.png'

export default function DropDown() {
  const { mutate } = useLogout()
  const { loggedInUserInfo } = useAuth()
  const imageURL = loggedInUserInfo?.image
    ? `${IMAGE_BASE_URL}${loggedInUserInfo?.image}`
    : defaultImage
  return (
    <div>
      <Menu as='div' className='relative inline-block text-left z-50'>
        <div>
          <Menu.Button className='inline-flex items-center gap-1 rounded-full justify-center text-xl text-current focus:outline-none focus-visible:ring-2 focus-visible:ring-white h-12 w-12 border focus-visible:ring-opacity-75 hover:text-primary transition duration-300 bg-gray-200 font-bold text-primary'>
            <img
              src={imageURL}
              alt={loggedInUserInfo?.name}
              className='h-full w-full rounded-full'
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute right-0 mt-1 w-52 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none py-4 text-base font-medium text-slate-600'>
            <Menu.Item className='block w-full'>
              <div>
                <div className='h-24 w-24 mx-auto'>
                  <img
                    src={imageURL}
                    alt={loggedInUserInfo?.name}
                    className='h-full w-full rounded-full'
                  />
                </div>
                <Link
                  to={`/user/${loggedInUserInfo?.id}`}
                  className='flex items-center gap-2 px-4 py-2 justify-center bg-primary mx-4 rounded-md text-white text-sm my-3 hover:bg-primary/90'
                >
                  View Profile
                </Link>
              </div>
            </Menu.Item>
            <Menu.Item className='block w-full'>
              <Link
                to='/recipe/write'
                className='flex items-center gap-2 px-4 py-2 hover:bg-slate-50'
              >
                <HiPencil />
                Add Recipe
              </Link>
            </Menu.Item>
            <Menu.Item className='block w-full'>
              <Link to='/ebook/add' className='flex items-center gap-2 px-4 py-2 hover:bg-slate-50'>
                <HiPencil />
                Add Ebooks
              </Link>
            </Menu.Item>
            <Menu.Item className='block w-full'>
              <Link
                to='/create-kitchen'
                className='flex items-center gap-2 px-4 py-2 hover:bg-slate-50'
              >
                <HiPencil />
                Create Kitchen
              </Link>
            </Menu.Item>
            <Menu.Item className='block w-full'>
              <button
                onClick={() => mutate()}
                className='flex items-center gap-2 px-4 py-2 hover:bg-slate-50'
              >
                <AiOutlineLogout />
                Logout
              </button>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
