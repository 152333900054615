// import greenLogo from 'assets/images/home/green-logo.webp'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { Link, NavLink } from 'react-router-dom'

// import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
// import { Disclosure } from '@headlessui/react'
// import { FiChevronDown } from 'react-icons/fi'
import { useLogout } from 'api/services/authtentication'
import logo from 'assets/images/logo.png'
import { navData } from 'data/navbar-data'
import useAuth from 'hooks/useAuth'

function SideNavbar({ open, handleOpen }) {
  const { loggedInUserInfo } = useAuth()
  const { mutate } = useLogout()

  const loginNavdata = [
    {
      id: 11,
      name: 'Add Recipe',
      link: '/recipe/write',
    },
    {
      id: 13,
      name: 'Add Ebooks',
      link: '/ebook/add',
    },
    {
      id: 14,
      name: 'Create Kitchen',
      link: '/create-kitchen',
    },
  ]

  return (
    <Drawer open={open} onClose={handleOpen} direction='left' className='lg:hidden'>
      <div className='px-4 pt-6 h-full text-slate-700 flex flex-col justify-between'>
        <div className=''>
          <Link to='/' className='font-bold italic text-2xl text-primary'>
            <img className='w-[130px] md:[160px] lg:w-[180px] h-auto' src={logo} alt='Alkhayer' />
          </Link>
          <ul className='mt-8'>
            {loggedInUserInfo && (
              <li>
                <NavLink
                  to={`/user/${loggedInUserInfo?.id}`}
                  className={({ isActive }) =>
                    ` block w-full hover:bg-primary hover:text-white transition-all duration-500 py-2 px-4 rounded ${
                      isActive
                        ? 'text-primary bg-primary/10 font-semibold'
                        : 'text-tertiary font-medium'
                    }`
                  }
                >
                  View Profile
                </NavLink>
              </li>
            )}
            {navData.map((menu) => (
              <li key={menu.id}>
                <NavLink
                  to={menu.link}
                  target={menu?.link.startsWith('https') ? '_blank' : '_self'}
                  className={({ isActive }) =>
                    ` block w-full hover:bg-primary hover:text-white transition-all duration-500 py-2 px-4 rounded ${
                      isActive
                        ? 'text-primary bg-primary/10 font-semibold'
                        : 'text-tertiary font-medium'
                    }`
                  }
                >
                  {menu.name}
                </NavLink>
              </li>
            ))}
            {!loggedInUserInfo && (
              <li>
                <NavLink
                  to='/login'
                  // target={menu?.link.startsWith('https') ? '_blank' : '_self'}
                  className={({ isActive }) =>
                    ` block w-full hover:bg-primary hover:text-white transition-all duration-500 py-2 px-4 rounded ${
                      isActive
                        ? 'text-primary bg-primary/10 font-semibold'
                        : 'text-tertiary font-medium'
                    }`
                  }
                >
                  Login
                </NavLink>
              </li>
            )}
            {loggedInUserInfo
              ? loginNavdata?.map((menu) => (
                  <li key={menu?.id}>
                    <NavLink
                      to={menu?.link}
                      target={menu?.link.startsWith('https') ? '_blank' : '_self'}
                      className={({ isActive }) =>
                        ` block w-full hover:bg-primary hover:text-white transition-all duration-500 py-2 px-4 rounded ${
                          isActive
                            ? 'text-primary bg-primary/10 font-semibold'
                            : 'text-tertiary font-medium'
                        }`
                      }
                    >
                      {menu.name}
                    </NavLink>
                  </li>
                ))
              : null}
          </ul>
        </div>
        {loggedInUserInfo && (
          <button
            onClick={() => mutate()}
            className='px-4 py-2 mb-6 w-full bg-primary text-white transition-all duration-500 rounded  font-semibold'
          >
            Logout
          </button>
        )}
      </div>
    </Drawer>
  )
}

export default SideNavbar

// function DropDown({ title, menus }) {
//   return (
//     <div className='mx-auto w-full max-w-md rounded-2xl bg-white my-2'>
//       <Disclosure>
//         {({ open }) => (
//           <>
//             <Disclosure.Button className='flex w-full justify-between rounded-lg px-4 text-left text-md font-medium text-slate-700 hover:bg-green-100 py-3 focus:outline-none focus-visible:ring focus-visible:ring-primary focus-visible:ring-opacity-75'>
//               <span className='text-slate-900'>{title}</span>
//               <FiChevronDown
//                 className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-primary`}
//               />
//             </Disclosure.Button>
//             <Disclosure.Panel className='px-4 pt-4 pb-2 text-sm text-gray-500'>
//               {menus.map((menu) => (
//                 <NavLink
//                   key={menu.id}
//                   to={menu.link}
//                   className={({ isActive }) =>
//                     ` block w-full hover:bg-green-100 hover:text-green-600 transition-all duration-500 py-2 px-4 rounded ${
//                       isActive
//                         ? 'text-green-600 bg-green-100 font-semibold'
//                         : 'text-tertiary font-medium'
//                     }`
//                   }
//                 >
//                   Home
//                 </NavLink>
//               ))}
//             </Disclosure.Panel>
//           </>
//         )}
//       </Disclosure>
//     </div>
//   )
// }
